<template>
  <div class="admin-wrap">
    <AdminMenu active-item="staffs" />
    <div class="admin-main">
      <AdminHeader title="員工報表" backlink="javascript:history.back()" />
      <div class="employee-report">
        <div class="employee-report__basic-info">
          <div class="employee-report__basic-info-inner">
            <div class="employee-report__col">
              <h3>{{staff.fullName}}</h3>
              <h4>{{staff.idNumber}}</h4>
              <div class="employee-report__date-picker">
                <input type="date" v-model="dateStart">～<input type="date" v-model="dateEnd">
              </div>
              <div class="employee-report__report-info">
                <div class="employee-report__chart">
                  <work-summary-pie :chart-data="chartData" v-bind:chartOptions="chartOptions"  v-if="isLoaded"></work-summary-pie>
                  <!-- <canvas id="report-chart" width="340" height="340"></canvas> -->
                </div>
                <div class="employee-report__work-list">
                  <p>任務總數：<strong>{{workSummary.totalTasks}}</strong></p>
                  <p>準時完成數量：<strong>{{workSummary.totalOntime}}</strong></p>
                  <p>逾時完成數量：<strong>{{workSummary.totalOverdue}}</strong></p>
                  <p>未完成數量：<strong>{{workSummary.totalUnfinished}}</strong></p>
                </div>
                <div class="employee-report__result-list">
                  <p>花費時間：<strong>{{workSummary.timeSpent}}</strong>分鐘</p>
                  <p>積分：<strong>{{workSummary.scoresEarned}}</strong>分</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="employee-report__history">
          <h3>任務歷程</h3>
          <div class="employee-report__history-list">
            <div class="employee-report__history-item" v-for="task in tasks" :key="task">
              <div class="employee-report__date">
                <span>日期：{{task.date}} </span>
                <span>預定時間：{{task.startTime}} ~ {{task.endTime}}   </span>
                <span>{{task.status}}</span>
              </div>
              <p class="employee-report__place">地點：{{task.spotName}}</p>
              <p class="employee-report__task">任務：{{task.description}}</p>
            </div>
          </div>
          <div class="admin-pagination">
            <button class="admin-pagination__btn-prev" :class="{ 'is-disable' : !canPrevPage }" @click="onPrevPage">上一頁</button>
            <span class="admin-pagination__current">{{page}}</span>
            <span class="admin-pagination__page">/ {{totalPages}}頁</span>
            <button class="admin-pagination__btn-next" :class="{ 'is-disable' : !canNextPage }" @click="onNextPage">下一頁</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { apiGetStaff, apiGetWorkSummary, apiGetTasks } from '@/api/v1/admin'
import { formatDate } from '@/utils/utils'
import WorkSummaryPie from '../../components/Pie'

export default {
  name: 'StaffReport',
  props: ['staffId'],
  components: { AdminMenu, AdminHeader, WorkSummaryPie },
  computed: {
    changeData () {
      const { staff, dateStart, dateEnd } = this
      return {
        staff,
        dateStart,
        dateEnd
      }
    },
    totalPages () {
      return Math.ceil(this.total / this.size)
    },
    canNextPage () {
      return this.page < this.totalPages
    },
    canPrevPage () {
      return this.page > 1
    }
  },
  data () {
    return {
      staff: '',
      workSummary: '',
      tasks: '',
      dateStart: '',
      dateEnd: '',
      page: 1,
      size: 10,
      total: 0,
      isLoaded: false,
      chartData: '',
      chartOptions: {
        responsive: true
      }
    }
  },
  created () {
    this.fetchData()
    const date = new Date()
    this.dateStart = formatDate(new Date(date.getFullYear(), date.getMonth(), 1))
    this.dateEnd = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0))
  },
  watch: {
    // load working summary if staffid > 0
    changeData: {
      handler: function (val) {
        if (this.staff.id > 0) {
          this.loadWorkSummary()
          this.loadTasks()
        }
      },
      deep: true
    },
    page: function (val) {
      if (this.staff.id > 0) {
        this.loadTasks()
      }
    }
  },
  methods: {
    fetchData () {
      if (this.staffId > 0) {
        apiGetStaff(this.staffId).then(data => {
          if (data.id) {
            this.staff = data
          }
        })
      }
    },
    loadWorkSummary () {
      this.isLoaded = false
      // Load work summary
      apiGetWorkSummary(this.staff.id, { ds: this.dateStart, de: this.dateEnd }).then(data => {
        console.log(data)
        if (!data.error) {
          this.workSummary = data
          this.chartData = {
            datasets: [
              {
                data: [data.totalOntime, data.totalOverdue, data.totalUnfinished],
                backgroundColor: [
                  '#6088a5',
                  '#e89741',
                  '#afac83'
                ],
                borderWidth: 1
              }],
            labels: ['準時完成', '逾時完成', '未完成']
          }
          this.isLoaded = true
        }
      })
    },
    loadTasks () {
      apiGetTasks(this.staff.id, { start: this.dateStart, end: this.dateEnd, page: this.page, size: this.size }).then(data => {
        if (data.error) {
          console.log(data.message)
        } else {
          this.tasks = data.content
          this.total = data.totalItems
        }
      })
    },
    onNextPage () {
      if (this.page < this.totalPages) {
        this.page++
      }
    },
    onPrevPage () {
      if (this.page > 1) {
        this.page--
      }
    }
  }
}
</script>

<style>

</style>
